import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './screen/home/Home';
import Dashboard from './screen/dashboard/Dashboard';
import NewProject from './screen/dashboard/NewProject';
import Register from './screen/auth/Register';
import Login from './screen/auth/Login';
import ChatView from './screen/dashboard/ChatView';

 
function App() {
  return (
    <Router>
        <Routes>
          <Route  path="/" element={<Home />} />
          <Route  path="/dashboard" element={<Dashboard />} />
          <Route  path="/signup" element={<Register />} />
          <Route  path="/login" element={<Login />} />
          <Route  path="/new-project" element={<NewProject />} />
          <Route  path="/chatbot" element={<ChatView />} />
          {/* <Route path="*" element={<NotFound/>}/> */}
        </Routes>
    </Router>
   );
}

export default App;
