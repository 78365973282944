/**
 * API environment url
 */

////////////auth
export const loginUrl = "login";

export const walletRegisterUrl = "applicant-wallet";

export const registerUrl = "users";
export const registerCompanyUrl = "company-register";

//Basic Auth endpoints
export const validatePhoneAndEmailUrl = "auth/validate_auth_phone_and_email";
export const validateRegistrationUrl = "auth/verify_registration_otp";
export const resendVerificationUrl = "auth/resend_authentication_otp";
export const forgotPasswordUrl = "forgot-password";
export const changePasswordUrl = "change-password";
export const forgotPasswordOtpUrl = "auth/verify_forgot_password_otp";
export const resetPasswordUrl = "auth/validate_forgot_password";
export const resetPasswordOTPUrl = "reset-password";

export const postUpdateApplicantProfileURL = "";
export const projectsURL = "projects";