//@ts-ignore
import { call, put, takeEvery } from "redux-saga/effects";
import cogoToast from "cogo-toast";

//services
import authServices from "../../api/services/auth";

//redux
import {
  LOGIN_USER,
  REGISTER_USER,
  VALIDATE_PHONE_AND_EMAIL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_OTP_VERIFY,
  VALIDATE_REGISTRATION,
  RESEND_VERIFICATION,
  CHANGE_PASSWORD,

} from "./constants";
import {
  apiError,
  loginSuccess,
  registerSuccess,
  registerFailure,
  forgotPasswordUserSuccess,
  VerifyOtpPasswordResetSuccess,
  validatePhoneAndEmailSuccess,
  validateRegistrationSuccess,
  resendVerificationSuccess,
  postNewPasswordSuccess,
  passwordOtpResetSuccess,
  postUpdateCompanyProfileSuccess,

} from "./actions";
import { ResponseGenerator } from "../../store/type";

function* loginUser({ payload }: any) {
  try {
    const { user, redirect } = payload;
    const response: ResponseGenerator = yield call(authServices.login, user);

    let data = response?.data
    let token = data?.token;
    let authData = data?.user;
      yield put(loginSuccess(response));
      localStorage.setItem("AuthData", JSON.stringify(authData));
      localStorage.setItem("token", JSON.stringify(token));
      // console.log(`token=== ${token}  authData== ${authData} `);
       window.location.href = "/dashboard";

  } catch (error:any) {
    console.log(`error=== ${error}`)
    const message = "network error";

    yield put(apiError(message));
    cogoToast.error("Invalid Credentials. Please try again");
  }
}

function* passwordOtpReset({ payload }: any) {
  try {
    const { user, callback } = payload;
    const response: ResponseGenerator = yield call(
      authServices.resetPasswordOTP,
      user
    );

    //if (response.data.status === "success") {
    yield put(passwordOtpResetSuccess(response.data));
    // callback(3);

    cogoToast.success("user password successfully reset").then(() => {
      window.location.href = "login";
    });
    // }
  } catch (error:  any) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    yield put(apiError(message));
  }
}

function* forgotPasswordUser({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      authServices.forgotPassword,
      payload.user
    );
    if (response.data) {
      yield put(forgotPasswordUserSuccess(response.data));
      localStorage.setItem("forgotPasswordOTP", response.data.otp);
      cogoToast
        .success("Password reset link successfully sent to your email.")
        .then(() => {});
    }
  } catch (error:  any) {
    const message = "error";
  }
}

function* VerifyOtpPasswordReset({ payload }: any) {
  try {
    const { user, callback, setResetTokenCallback } = payload;
    const response: ResponseGenerator = yield call(
      authServices.forgotPasswordOtp,
      user
    );
    if (response.data.status === "success") {
      yield put(VerifyOtpPasswordResetSuccess(response.data));
      setResetTokenCallback(response.data.data.password_reset_token);
      callback(3);
    }
  } catch (error:  any) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";

    yield put(apiError(message));
  }
}

function* postNewPassword({ payload }: any) {
  try {
    const { user, callback } = payload;
    const response: ResponseGenerator = yield call(
      authServices.changePassword,
      user
    );

    if (response.data.success) {
      yield put(postNewPasswordSuccess(response.data));
      // callback(3);

      cogoToast.success("user password successfully reset").then(() => {
         window.location.href = "";
      });
    }
    else{
      cogoToast.error("Old Password did not match. Try again").then(() => {

     });
    }
  } catch (error:  any) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    yield put(apiError(message));
  }
}

function* registerUser({ payload }: any) {
  try {
    const { user, redirect } = payload;
    let useService = authServices.register;
   
    const response: ResponseGenerator = yield call(authServices.register, user);
      yield put(registerSuccess(response.data));
      cogoToast.success(
        "Sign Up Successful. Welcome to classify. You will be redirected to login"
      );
      setTimeout(() => {
        window.location.href = "/login";        
      }, 3000);

  } catch (error:  any) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    cogoToast.error(message);
    yield put(apiError(message));
  }
}


function* validatePhoneAndEmail({ payload }: any) {
  try {
    const { data, callback } = payload;
    const response: ResponseGenerator = yield call(
      authServices.validatePhoneAndEmail,
      data
    );
    if (response.data.status === "success") {
      yield put(validatePhoneAndEmailSuccess(response.data));
      callback(2);
    }
  } catch (error:  any) {
    console.log(error.response.data.message);
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    yield put(apiError(message));
  }
}

function* validateRegistration({ payload }: any) {
  try {
    const { data, redirect } = payload;
    const response: ResponseGenerator = yield call(
      authServices.validateRegistration,
      data
    );
    console.log(response.data);
    if (response.data) {
      yield put(validateRegistrationSuccess(response.data));
      window.location.replace(`${window.location.origin}${redirect}`);
    }
  } catch (error:  any) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    yield put(apiError(message));
  }
}

function* resendVerification({ payload }: any) {
  try {
    const { data, redirect } = payload;

    const response: ResponseGenerator = yield call(
      authServices.resendVerification,
      data
    );

    console.log(response.data.data);

    if (response.data) {
      yield put(resendVerificationSuccess(response.data.data));

      cogoToast.success("otp resent, check your e-mail").then(() => {
        window.location.replace(
          `${window.location.origin}${redirect}&email=${response.data.data.email}&token=${response.data.data.verify_token}`
        );
      });
    }
  } catch (error:  any) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";

    yield put(apiError(message));
  }
}

 


function* authSaga() {
  yield takeEvery(LOGIN_USER.REQUEST, loginUser);
  yield takeEvery(REGISTER_USER.REQUEST, registerUser);
  yield takeEvery(FORGOT_PASSWORD.REQUEST, forgotPasswordUser);
  yield takeEvery(FORGOT_PASSWORD_OTP_VERIFY.REQUEST, VerifyOtpPasswordReset);
  yield takeEvery(CHANGE_PASSWORD.REQUEST, postNewPassword);
  yield takeEvery(VALIDATE_PHONE_AND_EMAIL.REQUEST, validatePhoneAndEmail);
  yield takeEvery(VALIDATE_REGISTRATION.REQUEST, validateRegistration);
  yield takeEvery(RESEND_VERIFICATION.REQUEST, resendVerification);  
}

export default authSaga;
