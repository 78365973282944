import { useState, useCallback, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Header from '../../components/Header';
import Hero from '../../components/Hero';
import Sidebar from '../../components/Sidebar';
import ClipLoader from "react-spinners/ClipLoader";
import { projectCreate } from "../../store/actions";
// import Dropzone from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import PDFImg from '../../assets/img/pdf.png';
import EXCELImg from '../../assets/img/excel.png';
import MP4Img from '../../assets/img/mp4.png';
import AudioImg from '../../assets/img/audio.png';

 


function NewProject(props: any) {
  const { projectCreate, error, loading, projectData, currentPathName } = props;

  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [youtubeLink, setYoutubeLink] = useState('');
  const [classification_type, setClassificationType] = useState();
  const [todos, setTodos] = useState<string[]>([]);
  const [newTodo, setNewTodo] = useState('');
  const [todoFiles, setTodoFiles] = useState<{ todo: string; files: File[] }[]>([]);
  const [hasCreatedProject, setHasCreatedProject] = useState(false);

  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles: any) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true });


  const handleRemoveFile = (fileToRemove: any) => {
    setFiles(files.filter((file) => file !== fileToRemove));
  };

  const createProject = async (data: object) => {
   await projectCreate(data);
  }

  useEffect(()=>{
     if(projectData !== null){
      console.log(`projectData===useEffect ${JSON.stringify(projectData)}`);
      uploadFiles();
    }
  }, [projectData])

  const uploadFiles = async () => {
    if(projectData?.id !== undefined && !hasCreatedProject){
      
    try {
      console.log("Uploading files...", files);
  
      for (const file of files) {
        const formData = new FormData();

       if(classification_type !== 'youtube'){
        // Check if the file is an array (multiple files) or a single file
        if (Array.isArray(file)) {
          // Handle multiple files with the same name
          //@ts-ignore
          formData.append('file_name', file[0]?.name);
          //@ts-ignore
          formData.append('project_type', classification_type);
          
          for (const filex of file) {
            formData.append('files', filex);
          }
        } else {
          // Handle a single file
          //@ts-ignore
          formData.append('file_name', file?.name);
          formData.append('project_id', projectData?.id);
          //@ts-ignore
          formData.append('project_type', classification_type);
          formData.append('files', file);
        }
      }
      else {
         
         //@ts-ignore
         formData.append('project_type', classification_type);
         formData.append('project_id', projectData?.id);
         formData.append('youtubelinks', youtubeLink);

      }
        // const response = await fetch('http://gw-ai.filetawk.xyz/llama-pdf', {
        //   method: 'POST',
        //   body: formData,
        // });

        const response = await fetch('https://gw-ai.filetawk.xyz/upload-files', {
            method: 'POST',
            body: formData,
            headers: {
              'Accept':'application/json'
            },
          });
  
        console.log(` end === ${JSON.stringify(response)}`);
        setHasCreatedProject(true);
        // Handle the response accordingly (e.g., check if the upload was successful)
      }
      window.location.href='/dashboard';
    } catch (error) {
      console.error('Error:', error);
    }

  }
  };
  

  
  // const uploadFiles = async () => {
  //   try {
  //     console.log("Uploading files...", files);
  //     for (const file of files) {
  //       const formData = new FormData();
  //       //@ts-ignore
  //        formData.append('file_name', file?.name);
  //        formData.append('project_id', projectData?.id);        
  //        //@ts-ignore
  //       for (const filex of file.files) {
  //         formData.append('files', filex);
  //       }

  //       const response = await fetch('http://gw-ai.filetawk.xyz/llama-pdf', {
  //         method: 'POST',
  //         body: formData,
  //       });
        
  //       console.log(` end === ${JSON.stringify(response)}`);
  //       // if (response.ok) {
  //       //   console.log(`Files uploaded for ${todo.todo}`);
  //       // } else {
  //       //   console.error(`Error uploading files for ${todo.todo}`);
  //       // }
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  return (
    <div className="flex">
      {/* <Sidebar /> */}

      <div className="background-image text-white h-screen w-64 flex-shrink-0">
        <div className="p-4">
        <a href="/dashboard" className="border flex border-gray-400 hover:border-gray-600 text-gray-700 hover:text-gray-900 font-semibold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-gray-300">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M20 11H7.414l3.293-3.293a1 1 0 0 0-1.414-1.414l-5 5a1 1 0 0 0 0 1.414l5 5a1 1 0 0 0 1.414-1.414L7.414 13H20a1 1 0 0 0 0-2z"/>
          </svg> Dashboard
        </a>
      </div>
      <div className="p-4">
        <h3 className="text-2xl font-semibold text-gray-900">
          <a href="/dashboard" style={{ maxWidth: "100%" }}>
            {projectData?.data?.title}
          </a>
        </h3>
      </div>
    </div>

      <main className="flex-1 p-10">
        {/**  Main Start */}
        <section className="text-gray-600 body-font">
        <div className="flex justify-between items-center mb-4 shadow-lg py-5 px-5">
          <h2 className="text-lg font-semibold">New Project</h2>
        </div>
    

          <div className="container px-5 py-10 mx-auto">
         
          <Formik
        enableReinitialize={true}
        initialValues={{
          title: title,
          description: description,
          classification_type: classification_type,
          youtube_link: youtubeLink
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required("Project title is required "),
          description: Yup.string().required(
             "Description required"
          ),

          classification_type: Yup.string()
            .required("Document type required")
            // .matches(
            //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
            //   "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            // ),
        })}
        onSubmit={(values, { resetForm }) => {
          let data = {
            title: values?.title || "", 
            description: values?.description || "", 
            classification_type: values?.classification_type || "", 
          };
          
          console.log(`data === ${JSON.stringify(data)}`);
          createProject(data);
          /*
          setShowThankYou(true)
          */
        }}
      >
        {({ errors, status, touched }) => (
          <Form>
            <div>
              <label
                id="title"
                className="text-sm font-small leading-none DMSansBold  text-soft-gray"
              >
                {"Title"}
              </label>

              <Field
                name="title"
                type="text"
                onChange={(e:any)=>{
                  setTitle(e.target.value);
                 }}
                className={
                  "bg-white-200 border rounded border-black-200-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                   //@ts-ignore
                  (errors.title && touched.title ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="title"
                component="div"
                className="invalid-feedback"
              />
            </div>
            
            <div className="mt-6  w-full">
              <label
                id="description"
                className="text-sm font-small leading-none DMSansBold  text-soft-gray"
              >
                Description
              </label>
              <Field
                name="description"
                onChange={(e:any)=>{
                  setDescription(e.target.value);
                 }}
                type="text"
                className={
                  "bg-white-200 border rounded border-black-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                  //@ts-ignore
                  (errors.description && touched.description ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="description"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="mt-6  w-full">
              <label className="text-sm font-small DMSansBold  text-soft-gray">
               Document Type
              </label>
              <div className="input-group auth-pass-inputgroup">
              {/* <select id="classification_type" name="classification_type" 
                      className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500 text-gray-700">
                  <option value="web">PDF</option>
                  <option value="mobile">Excel/CSV</option> 
                  <option value="video">Video mp4</option>
                  <option value="audio">Audio mp3</option>
                  <option value="audio">Youtube</option>
              </select> */}

                <Field as="select" onChange={(e:any)=>{
                    setClassificationType(e.target.value);
                }} id="classification_type"
                value={classification_type}
                name="classification_type" className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500 text-gray-700" >
                  <option value="">Select an option</option>
                  <option value="pdf">PDF</option>
                  <option value="excel">Excel/CSV</option> 
                  <option value="video">Video mp4</option>
                  <option value="audio">Audio mp3</option>
                  <option value="youtube">Youtube</option>
                </Field>  

            
              
                {/* <label htmlFor="selectedOption">Select an option:</label>
                  <Field as="select" name="selectedOption">
                    <option value="">Select an option</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </Field>
                </div> */}

                {/* <Field
                  name="classification_type"
                  type="classification_type"
                  autoComplete="true"
                  className={
                    "bg-white-200 border rounded border-black-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                    (errors.classification_type && touched.classification_type ? " is-invalid" : "")
                  }
                /> */}
                <button
                  className="btn btn-light "
                  type="button"
                  id="classification_type-addon"
                >
                  <i className="mdi mdi-eye-outline"></i>
                </button>
              </div>
              <ErrorMessage
                name="classification_type"
                component="div"
                className="invalid-feedback"
              />

             
            </div>

        {classification_type !== 'youtube' && 
            <div className="bg-gray-200 p-6 border-dashed border-2 border-gray-400 rounded-lg text-center" style={{ marginTop: '5%' }}>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p className="py-20 customPicker">Drag & drop some files here, or click to select files</p>
              </div>
              <div className="file-preview">
                {files.map((file) => (
                  //@ts-ignore
                  <div className="p-2 sm:w-full w-full" key={file.name}>
                    <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                    
                      {classification_type == 'pdf' && 
                        <>
                        <img src={PDFImg} alt="File" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"  style={{width: 25, height: 30}}  />
                        </>
                      }

                      {classification_type == 'excel' && 
                        <>
                        <img src={EXCELImg} alt="File" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"  style={{width: 25, height: 30}}  />
                        </>
                      }

                      {classification_type == 'video' && 
                        <>
                        <img src={MP4Img} alt="File" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"  style={{width: 25, height: 30}}  />
                        </>
                      }
                        {classification_type == 'audio' && 
                        <>
                        <img src={AudioImg} alt="File" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"  style={{width: 25, height: 30}}  />
                        </>
                      }

 


                      {/* @ts-ignore */}
                      <span className="title-font font-medium">{file.name}</span>
                      <button
                        className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                        style={{ width: 40, height: 40 }}
                        onClick={() => handleRemoveFile(file)}
                      >
                        X
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
        }

    {classification_type == 'youtube' && 
        <div className="mt-6  w-full">
              <label
                id="youtube_links"
                className="text-sm font-small leading-none DMSansBold  text-soft-gray"
              >
                Youtube Links (seperate multiple links with comma)
              </label>
              <Field
                name="youtube_links"
                onChange={(e:any)=>{
                  setYoutubeLink(e.target.value);
                 }}
                type="text"
                className={
                  "bg-white-200 border rounded border-black-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                  //@ts-ignore
                  (errors.youtube_links && touched.youtube_links ? " is-invalid" : "")
                }
              />
             
            </div>
        }

            <div className="mt-8">
              {  
              //@ts-ignore 
              loading ? (
                <div className="text-center">
                  <ClipLoader color={"#3b82f6"} loading={true} size={50} />
                </div>
              ) : (
                <button
                  role="button"
                  type="submit"
                  className="focus:ring-2 focus:ring-offset-2  DMSansBold  text-sm font-semibold leading-none text-white focus:outline-none bg-blue-700 border rounded bg-bee-orange py-4 w-full"
                >
                  Continue
                </button>
              )}
            </div>
            
          </Form>
        )}
          </Formik>
          </div>


        </section>

        {/** Main Ends */}
      </main>
    </div>
  );
}
//projectsCreate
const mapStateToProps = (state: any) => {
  const { error, loading, projectData } = state.project;
  return { error, loading, projectData };
};

export default connect(mapStateToProps, { projectCreate })(NewProject);


 