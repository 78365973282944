import React, { useState, useEffect } from "react";
import Header from '../../components/Header';
import Hero from '../../components/Hero';
import Sidebar from '../../components/Sidebar';
import { connect } from "react-redux";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { fetchChatBotDetail } from "../../store/actions";

import PDFImg from '../../assets/img/pdf.png';
import EXCELImg from '../../assets/img/excel.png';
import MP4Img from '../../assets/img/mp4.png';
import AudioImg from '../../assets/img/audio.png';

function ChatView(props: any) {
  const { fetchChatBotDetail, error, loading, projectData } = props;
  const [isLoading, setIsLoading] = useState(false);  
  const [messages, setMessages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const urlString = window.location.href;
  const url = new URL(urlString);
  // Get the value of the "code" parameter
  const pid = url.searchParams.get("pid");

  useEffect(()=>{
    runFetchChatbotDetails();
  }, [1]);

  const runFetchChatbotDetails = async () => {
    await fetchChatBotDetail({id:pid});
    //@ts-ignore
    await getFileLists(pid);

  }

  // useEffect(()=>{
  //     console.log(`projectData=== ${JSON.stringify(projectData)}`)
  // },[projectData])
  
  useEffect(()=>{
    console.log(`messages=== ${JSON.stringify(messages)}`)
  },[messages])
  const handleSendMessage = () => {
    setIsLoading(true);
    if (newMessage.trim() !== '') {
      //@ts-ignore
      // setMessages([...messages, { text: newMessage, sender: 'user' }]);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: newMessage, sender: 'user' },
      ]);
      getGPTResult(newMessage);
      setNewMessage('');
    }

  };


  //TODO: fix later on

  const getFileLists = async (pid: string) => {
    try {
      const response = await axios.post('https://gw-ai.filetawk.xyz/list-files', {
        input: newMessage,
        project_id: pid,
        project_type:  projectData?.data?.classification_type
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setFileList(response?.data?.message);
      console.log(response?.data?.message);

    } catch (error) {
      

    }

  }


  const getGPTResult = async (newMessage: string) => {
    try {
      const response = await axios.post('https://gw-ai.filetawk.xyz/gpt', {
        input: newMessage,
        project_id: projectData?.data?.id,
        project_type:  projectData?.data?.classification_type
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // console.log(JSON.stringify(response.data.message));
      // @ts-ignore
      // setMessages([...messages, { text: response.data.message.replaceAll('\n','<br/>'), sender: 'beegpt' }]);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: response.data.message.replaceAll('\n','<br/>'), sender: 'beegpt' },
      ]);
      setIsLoading(false);
        
    } catch (error) {
      console.error(error);
      setIsLoading(false);

    }

  }

  return (
    <div className="flex">

      {/* <Sidebar /> */}
      <div className="background-image text-white h-screen w-64 flex-shrink-0">
        <div className="p-4">
        <a href="/dashboard" className="border flex border-gray-400 hover:border-gray-600 text-gray-700 hover:text-gray-900 font-semibold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-gray-300">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M20 11H7.414l3.293-3.293a1 1 0 0 0-1.414-1.414l-5 5a1 1 0 0 0 0 1.414l5 5a1 1 0 0 0 1.414-1.414L7.414 13H20a1 1 0 0 0 0-2z"/>
          </svg> Dashboard
        </a>
      </div>
      <div className="p-4">
        <h3 className="text-2xl font-semibold text-gray-900">
          <a href="/dashboard" style={{ maxWidth: "100%" }}>
            {projectData?.data?.title}
          </a>
        </h3>
      </div>
      <ul className="mt-6">
        {fileList?.map((item, index) => {
          return (
            <li className="mb-2">
              <a href="#" className="block flex text-gray-900 px-4 py-2 hover:bg-blue-700x" style={{ maxWidth: "100%" }}>
                {projectData?.data?.classification_type === 'pdf' && (
                  <>
                    <img src={PDFImg} alt="File" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" style={{ width: 25, height: 30 }} />
                  </>
                )}
                {projectData?.data?.classification_type === 'excel' && (
                  <>
                    <img src={EXCELImg} alt="File" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" style={{ width: 25, height: 30 }} />
                  </>
                )}
                {projectData?.data?.classification_type === 'video' && (
                  <>
                    <img src={MP4Img} alt="File" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" style={{ width: 25, height: 30 }} />
                  </>
                )}
                {projectData?.data?.classification_type === 'audio' && (
                  <>
                    <img src={AudioImg} alt="File" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" style={{ width: 25, height: 30 }} />
                  </>
                )}
               

                {/* @ts-ignore */}
                <span className="text-sm max-w-32 overflow-hidden text-ellipsis">{item.split('/').pop()}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>


      <main className="flex-1 p-10">

        {/**  Main Start */}

        <section className="text-gray-600 body-font" style={{marginTop: '3%'}}>
          <div className="container px-5 py-24x mx-auto">
            <div className="flexx flex-wrap -m-4 shadow">
             
{/*             
               <div className="p-4 border-b">
                <p className="text-xl font-semibold"> {projectData?.data?.title}</p>
              </div> */}

              <div className="p-4 overflow-y-auto" style={{height: 550, overflowY: 'scroll'}}>
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`my-2 ${
                      //@ts-ignore
                      message.sender === 'user' ? 'text-right' : 'text-left'
                    }`}
                  >
                    <div
                      className={`inline-block rounded p-2 break-words ${
                          //@ts-ignore
                        message.sender === 'user'
                          ? 'gb-crime-yellow text-white'
                          : 'bg-gray-200'
                      }`}
                    >
                  
                      {/* {message.text} */}
                      {/** @ts-ignore */}
                      <div dangerouslySetInnerHTML={{ __html: message.text }} />

                    </div>
                  </div>
                ))}
              </div>
            <div className="p-4 flex">
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Send a message"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
              {
                  isLoading ?    
                  <ClipLoader color={"#3b82f6"} loading={true} size={50} />
                  :
              <button
                  className="mt-2 gb-crime-yellow text-white py-2 px-4 rounded ml-5 mb-1"
                  onClick={handleSendMessage}
                >
                          <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>


                </button>}
              </div>
          

           


 



            </div>
          </div>
        </section>

        {/** Main Ends */}

      </main>

    
    </div>

  );
}


const mapStateToProps = (state: any) => {
  const { error, loading, projectData } = state.project;
  return { error, loading, projectData };
};

export default connect(mapStateToProps, { fetchChatBotDetail })(ChatView);


