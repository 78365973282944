import { combineReducers } from "redux";

//User
import auth from "../store/auth/reducer";
import project from "../store/project/reducer";

const rootReducer = combineReducers({
  //public
  auth,
  project
});

export default rootReducer;
