import { post, put, get } from "../../../helpers/axios";


import {
  projectsURL,
} from "../../endpoints";

 const projectsCreate = (data: object) => {
  return post(projectsURL, data);
};

const fetchProjects= (config: any) => {
  return get(projectsURL, config);
};

const fetchChatBotDetail= (config: any) => {
  return get(projectsURL+'/'+config.id, config);
};


const projectServices = {
  projectsCreate,
  fetchProjects,
  fetchChatBotDetail
};
export default projectServices;
