import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Sidebar from '../../components/Sidebar';
import { fetchProjects } from "../../store/actions";
import PDFImg from '../../assets/img/pdf.png';
import EXCELImg from '../../assets/img/excel.png';
import MP4Img from '../../assets/img/mp4.png';
import AudioImg from '../../assets/img/audio.png';
import YoutubeImg from '../../assets/img/youtube.png';
import avatarImg from '../../assets/img/avatar.jpeg';
import logoImg from '../../assets/img/logo.png';

 

function Dashboard(props: any) {
  const { fetchProjects, error, loading, projectData } = props;

  useEffect(()=>{
    runFetchProject();
  }, [1]);

  const runFetchProject = async () => {
    await fetchProjects({});
  }
  
  // useEffect(()=>{
  //   console.log(JSON.stringify(projectData))
  // },[projectData])

  return (
    <div className="flexx">
 
      {/* <Sidebar /> */}
      <div className="bg-gray-900">
      <div className="container mx-auto flex justify-between items-center p-4">
          <div className="flex space-x-4">
              {/* <a href="/" className="text-white font-semibold">FileTawk</a>  */}
              <a href="/" className="text-white font-semibold">  <img src={logoImg} className="w-100 h-10" /> </a>

          </div>
          <div className="relative group">
              <button className="text-white group-hover:bg-gray-800 px-3 py-2 rounded-full focus:outline-none">
                  <img src={avatarImg} alt="Profile" className="h-8 w-8 rounded-full" />
              </button>
              <ul className="absolute hidden mt-2 space-y-2 bg-white text-gray-900 rounded-md shadow-lg z-10 group-hover:block">
                  <li><a href="/login" className="block px-4 py-2">Sign Out</a></li>
                    </ul>
                </div>
            </div>
        </div>
        
      <main className="flex-1 p-10">
         {/**  Main Start */}
 
        <section className="text-gray-600 body-font">
        <div className="flex justify-between items-center mb-4 shadow-lg py-5 px-5">
          <h2 className="text-lg font-semibold">Projects</h2>
          <a href='new-project' className="bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600">Create Project</a>
        </div>

          <div className="container px-5 py-10 mx-auto">
         
             <div className="flex flex-wrap -m-4">

              {projectData && projectData?.data?.map((item :object, index: number) => {
                return(
                  <div className="p-4 md:w-1/3 h-50">
                  <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                    <div className="p-6">
                      {/* @ts-ignore */}
                      {item?.classification_type === 'pdf' && (
                        <>
                          <img src={PDFImg} alt="File" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" style={{ width: 25, height: 30 }} />
                        </>
                      )}
                      {/* @ts-ignore */}
                          {item?.classification_type === 'excel' && (
                        <>
                          <img src={EXCELImg} alt="File" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" style={{ width: 25, height: 30 }} />
                        </>
                      )}
                      {/* @ts-ignore */}
                          {item?.classification_type === 'video' && (
                        <>
                          <img src={MP4Img} alt="File" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" style={{ width: 25, height: 30 }} />
                        </>
                      )}
                      {/* @ts-ignore */}
                          {item?.classification_type === 'audio' && (
                        <>
                          <img src={AudioImg} alt="File" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" style={{ width: 25, height: 30 }} />
                        </>
                      )}
                      {/* @ts-ignore */}
                          {item?.classification_type === 'youtube' && (
                        <>
                          <img src={YoutubeImg} alt="File" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" style={{ width: 25, height: 30 }} />
                        </>
                      )}
                      <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                        {/* @ts-ignore */}
                        {item?.classification_type}                        
                      </h2>
                      <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                        {/* @ts-ignore */}
                        {item?.title}
                      </h1>
                      <p className="leading-relaxed mb-3">
                        {/* @ts-ignore */}
                        {item?.description}
                      </p>
                      <div className="flex items-center flex-wrap">
                        {/* @ts-ignore */}
                        <a href={`chatbot?pid=${item?.id}`} className="bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600 text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                          View Chatbot
                        </a>
                     
                      </div>
                    </div>
                  </div>
                </div>
                )
              })         
                        
              }
             


            </div>
          </div>
        </section>

        {/** Main Ends */}

      </main>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  const { error, loading, projectData } = state.project;
  return { error, loading, projectData };
};

export default connect(mapStateToProps, { fetchProjects })(Dashboard);


 
 