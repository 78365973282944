import chatImg from '../assets/img/bg.png';
import screencapturedImg from '../assets/img/screencaptured.png';

import PDFImg from '../assets/img/pdf.png';
import EXCELImg from '../assets/img/excel.png';
import MP4Img from '../assets/img/mp4.png';
import AudioImg from '../assets/img/audio.png';
import YoutubeImg from '../assets/img/youtube.png';
 
const Hero = () => {
    return(
        <section className="text-gray-600 body-font background-image min-h-screen">
        <div className="container mx-auto flex px-5 py-20 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-left text-3xl mb-4 mt-8 font-medium text-gray-900 custom-heading-home"> 
            Chat with multiple media files and documents
              {/* <br className="hidden lg:inline-block"/>Classification Simplified */}
            </h1>
            <p className="mb-8 leading-relaxed notoSans">
            Create a completely automated AI chatbot, extract information from files and documents - PDF, Excel, CSV, Audio, Video, and Youtube.
                </p>
                <div className="grid grid-cols-5 gap-4 mb-10">
                  <div className="bg-gray-200 p-4 rounded text-center">
                     <img src={PDFImg} className="max-h-10 max-w-10" />
                  </div>
                  <div className="bg-gray-200 p-4 rounded text-center">
                     <img src={EXCELImg} className="max-h-10 max-w-10" />
                  </div>
                  <div className="bg-gray-200 p-4 rounded text-center">
                     <img src={MP4Img} className="max-h-10 max-w-10" />
                  </div>
                  <div className="bg-gray-200 p-4 rounded text-center">
                     <img src={AudioImg} className="max-h-10 max-w-10" />
                  </div>
                  <div className="bg-gray-200 p-4 rounded text-center">
                     <img src={YoutubeImg} className="max-h-10 max-w-10" />
                  </div>
 
                  </div>


            <div className="flex justify-center">
              <a href="signup" className="inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Get Started</a>
              {/* <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button> */}
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img className="object-cover object-center rounded bordered" alt="hero" src={screencapturedImg} />
          </div>
        </div>
      </section>
    )
}
export default Hero; 
 