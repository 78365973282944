import logoImg from '../assets/img/logo.png';

const Header = () => {
    return(
    <header className="text-gray-600 body-font shadow-lg bg-transparent  ">
    <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a href="/" className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
        {/* <span className="ml-3 text-xl">FileTawk</span> */}
        <img src={logoImg} className="w-100 h-10" />
        {/* logo.png */}
        </a>
        <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
        <a className="mr-5 hover:text-gray-900">Home</a>
        <a className="mr-5 hover:text-gray-900">Use Cases </a>
        <a className="mr-5 hover:text-gray-900">Pricing</a>
        <a  href="/signup" className="mr-5 hover:text-gray-900">Sign Up</a>

        </nav>
        <a href="/login" className="inline-flex items-center bg-blue-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0 text-white">Login
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
        </a>
    </div>
    </header>
    )
}

export default Header; 
 