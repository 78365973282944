import {
  PROJECT_CREATE,
  PROJECT_CREATE_SUCCESS,
  PROJECT_LIST,
  PROJECT_LIST_SUCCESS,
  CHATBOT_DETAILS,
  CHATBOT_DETAILS_SUCCESS,
} from "../../store/project/constants";

export const projectCreate = (response: any) => {
  return {
    type: PROJECT_CREATE,
    payload: response,
  };
};

export const projectCreateSuccess = (response: any) => {
  return {
    type: PROJECT_CREATE_SUCCESS,
    payload: response,
  };
};
 
export const fetchProjects = (response: any) => {
  return {
    type: PROJECT_LIST,
    payload: response,
  };
};

export const fetchProjectsSuccess = (response: any) => {
  return {
    type: PROJECT_LIST_SUCCESS,
    payload: response,
  };
};

export const fetchChatBotDetail = (response: any) => {
  return {
    type: CHATBOT_DETAILS,
    payload: response,
  };
};

export const fetchChatBotDetailSuccess = (response: any) => {
  return {
    type: CHATBOT_DETAILS_SUCCESS,
    payload: response,
  };
};

 