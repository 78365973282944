import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import Header from '../../components/Header';
import { loginUser } from "../../store/actions";

function Login(props: any) {
  const { loginUser, error, loading, user_data, currentPathName } = props;
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();


  useEffect(()=>{
    localStorage.clear();
  },[``])
  return (
    <div className="">
      <Header/>
   
      <section className="text-gray-600 body-font background-image h-screen ">
      <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
        <div className="lg:w-3/2 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
        <h1 className="title-font font-medium text-3xl text-gray-900 custom-heading-home">Login to your account started</h1>
        <p className="leading-relaxed mt-4">
        {/* Our A.I model has been trained with over 50+ laws on the nigerian constitution and laws. */}
        </p>
        </div>
        <div className="lg:w-3/6 md:w-1/2 bg-gray-100x rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
        {/* <h2 className="text-gray-900 text-lg font-medium title-font mb-5">Sign In</h2> */}
           <Formik
        enableReinitialize={true}
        initialValues={{
          email: email,
          password: password,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required("Email Address required "),
          password: Yup.string()
            .required("Password required")
            // .matches(
            //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
            //   "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            // ),
        })}
        onSubmit={(values, { resetForm }) => {
          let data = {
            email: values.email || "",
            password: values.password || "",
          };
          loginUser(data);
          // window.location.href='/dashboard';
        }}
      >
        {({ errors, status, touched }) => (
          <Form>
            <div className="mt-6  w-full">
              <label
                id="email"
                className="text-sm font-small leading-none DMSansBold  text-soft-gray"
              >
                Email Address
              </label>
              <Field
                name="email"
                type="text"
                className={
                  "bg-white-200 border rounded border-black-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="mt-6  w-full">
              <label className="text-sm font-small DMSansBold  text-soft-gray">
                Password
              </label>
              <div className="input-group auth-pass-inputgroup">
                <Field
                  name="password"
                  type="password"
                  autoComplete="true"
                  className={
                    "bg-white-200 border rounded border-black-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                    (errors.password && touched.password ? " is-invalid" : "")
                  }
                />
                <button
                  className="btn btn-light "
                  type="button"
                  id="password-addon"
                >
                  <i className="mdi mdi-eye-outline"></i>
                </button>
              </div>
              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="mt-8">
              {loading ? (
                <div className="text-center">
                  <ClipLoader color={"#3b82f6"} loading={true} size={50} />
                </div>
              ) : (
                <button
                  role="button"
                  type="submit"
                  className="focus:ring-2 focus:ring-offset-2  DMSansBold  text-sm font-semibold leading-none text-white focus:outline-none bg-blue-700 border rounded bg-bee-orange py-4 w-full"
                >
                  Continue
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
      <p className="py-5 fontgilory bee-text text-sm">
          <a href="/forgot-password">
            <span className="text-orange DMSansBold ">
              I've lost my password
            </span>
          </a>
        </p>
            </div>
    </div>
    </section>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  const { error, loading, user_data } = state.auth;
  return { error, loading, user_data };
};

export default connect(mapStateToProps, { loginUser })(Login);
