import {
  PROJECT_CREATE,
  PROJECT_CREATE_SUCCESS,
  CLEAR_API_ERROR,
  API_ERROR,
  PROJECT_LIST,
  PROJECT_LIST_SUCCESS,
  CHATBOT_DETAILS,
  CHATBOT_DETAILS_SUCCESS,
} from "../../store/project/constants";


const initialState = {
  error: "",
  apiError: null,
  loading: false,
  projectData: null,
  user_token: null,
  user_data: null, 
};

const auth = (state = initialState, action: any) => {
  switch (action.type) {
    case PROJECT_CREATE:
      state = {
        ...state,
        loading: true,
        error: "",
        projectData: action.payload,
      };
      break;
  
    case PROJECT_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        projectData: action.payload,
      };
      break;
    
    case PROJECT_LIST:
        state = {
          ...state,
          loading: true,
          error: "",
          projectData: action.payload,
        };
        break;
    
      case PROJECT_LIST_SUCCESS:
        state = {
          ...state,
          loading: false,
          error: "",
          projectData: action.payload,
        };
        break;
 
        case CHATBOT_DETAILS:
          state = {
            ...state,
            loading: true,
            error: "",
            projectData: action.payload,
          };
          break;
      
        case CHATBOT_DETAILS_SUCCESS:
          state = {
            ...state,
            loading: false,
            error: "",
            projectData: action.payload,
          };
          break;

    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;

    case CLEAR_API_ERROR:
      state = { ...state, error: "" };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default auth;
