//@ts-ignore
import { call, put, takeEvery } from "redux-saga/effects";
import cogoToast from "cogo-toast";

//services
import projectServices from "../../api/services/project";

import {
  PROJECT_CREATE,
  PROJECT_LIST,
  CHATBOT_DETAILS,
} from "../../store/project/constants";

import {
  projectCreate,
  projectCreateSuccess,
  fetchProjectsSuccess,
  fetchChatBotDetailSuccess
} from "./actions";
import { ResponseGenerator } from "../../store/type";
 

function* postProjectCreate({ payload }: any) {
  try {
    // console.log(`postProjectCreate=== ${JSON.stringify(payload)}`)
    const response: ResponseGenerator = yield call(
      projectServices.projectsCreate,
      payload
    );
    // console.log(`postProjectCreate=== ${JSON.stringify(response?.data)}`);
    if (response.data) {
      yield put(projectCreateSuccess(response.data));
      // cogoToast
      //   .success("Password reset link successfully sent to your email.")
      //   .then(() => {});
    }
  } catch (error:  any) {
    const message = "error";
  }
}
 

function* fetchProjects({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      projectServices.fetchProjects,
      payload
    );
     if (response) {
      yield put(fetchProjectsSuccess(response));
      // cogoToast
      //   .success("Password reset link successfully sent to your email.")
      //   .then(() => {});
    }
  } catch (error:  any) {
    const message = "error";
  }
}

function* fetchChatBotDetail({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      projectServices.fetchChatBotDetail,
      payload
    );
     if (response) {
      yield put(fetchChatBotDetailSuccess(response));
      // cogoToast
      //   .success("Password reset link successfully sent to your email.")
      //   .then(() => {});
    }
  } catch (error:  any) {
    const message = "error";
  }
}

function* projectSaga() {
  yield takeEvery(PROJECT_CREATE, postProjectCreate);  
  yield takeEvery(PROJECT_LIST, fetchProjects);  
  yield takeEvery(CHATBOT_DETAILS, fetchChatBotDetail);  
}

export default projectSaga;
