import { post, put, get, microserviceGet } from "../../../helpers/axios";
import axios from "axios";
// import Buffer from 'buffer'; // Import the 'buffer' module
import { Buffer } from 'buffer';

import {
  loginUrl,
  registerUrl,
  resendVerificationUrl,
  forgotPasswordUrl,
  validatePhoneAndEmailUrl,
  validateRegistrationUrl,
  forgotPasswordOtpUrl,
  changePasswordUrl,
  postUpdateApplicantProfileURL,
  resetPasswordUrl,
  resetPasswordOTPUrl,
} from "../../endpoints";

const login = async (data: object) => {
  console.log(`login=== ${JSON.stringify(data)}`)
  //@ts-ignore
  // const credentials = btoa(`${data?.email}:${data?.password}`);
  // const credentials = Buffer.from(`${data?.email}:${data?.password}`).toString('base64');
  let token = data?.email + ":" + data?.password;

  // Should i be encoding this value????? does it matter???
  // Base64 Encoding -> btoa
  let hash = btoa(token);  

  //@ts-ignore
  console.log(`Basic ${hash}`)

  // Create an Axios instance with the Authorization header
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`, // Replace with your API base URL
    headers: {
      'Authorization': `Basic ${hash}`,
      'Content-Type': 'application/json', // Set the content type based on your API
    },
  });
  // return await api.post(`${process.env.REACT_APP_API_URL}/auth`, null, {
  //   headers: {
  //     'Authorization': `Basic ${credentials}`
  //   }
  // });
  return api.post('/auth', data);
};

const register = (data: object) => {
  return post(registerUrl, data);
};

 

 
const postUpdateApplicantProfile = (data: object) => {
  return put(postUpdateApplicantProfileURL, data);
};

const forgotPassword = (data: object) => {
  return post(forgotPasswordUrl, data);
};

const resetPasswordOTP = (data: object) => {
  return post(resetPasswordOTPUrl, data);
};

const changePassword = (data: object) => {
  return post(changePasswordUrl, data);
};

const forgotPasswordOtp = (data: object) => {
  return post(forgotPasswordOtpUrl, data);
};
const validatePhoneAndEmail = (data: object) => {
  return post(validatePhoneAndEmailUrl, data);
};

const validateRegistration = (data: object) => {
  return post(validateRegistrationUrl, data);
};

const resendVerification = (data: object) => {
  return post(resendVerificationUrl, data);
};


 

const authServices = {
  login,
  register,
  validatePhoneAndEmail,
  validateRegistration,
  resendVerification,
  forgotPassword,
  forgotPasswordOtp,
  changePassword,
  postUpdateApplicantProfile,
  resetPasswordOTP
};
export default authServices;
